.Logo {
    margin: 0;
    padding: 0;
}
.Logo * {
    margin: 0;
    padding: 0;
}

.Logo h2 {
    display: inline-block;
    font-size: 3rem;
    font-weight: 100;
}

.Logo1 {
    font-family: 'Times New Roman', Times, serif;
}
.Logo2 {
    font-family: 'Commercial';
    font-style: normal;
    padding-left: 5px;
    padding-right: 3px;
    /*color: var(--primary);*/
}