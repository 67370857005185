.ActionButton {
    padding: 1rem;
    text-align: center;
}

.InnerButton {
    background-color: var(--accent);
    border-radius: 50%;
    font-size: 3rem;
    border-radius:400px;
    border: none;
    padding-left: 2rem;
    padding-right: 2rem;
    cursor:pointer;
    transition: all 0.5s;
}
.InnerButton:after {
    position: absolute;
    content: '»';
    opacity: 0;
    transition: 0.5s;
    padding-left: 3rem;
}
.InnerButton:hover{
  padding-right: 4rem;
}
.InnerButton:hover:after {
  opacity: 1;
  padding-left: 1rem;
}