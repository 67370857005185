.About {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.About * {
    margin: 1rem;
    font-size: 2rem;
    text-align: left;
}

.AboutImg img {
    object-fit: cover;
    width: 100%;
    margin: 0;
}
.AboutImg figcaption {
    font-size: 1.5rem;
}

.AboutImg {
    flex: 1;
    height: 100%;
    width: 50%;
    margin-right: 20px;

}

.MainInfo {
    flex: 1;
}

@media screen and (max-width: 800px) {
    .About {
        flex-direction: column;
    }
    .AboutImg {
        width: 90%;
    }
    .About img {
        width: 90%;
        margin:auto;
        margin-top: 2rem;
        order: 0;
    }
    .MainInfo {
        order: 1;
    }
}