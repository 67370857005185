.Grade {
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.TopGradingSection {
    display: flex;
}

.TopGradingSection > * {
    margin: 0.5rem;
}

.RandomPageButton {
    font-size: 2rem;

}

.TableContainer {
    margin: 1rem;
    width: 100%;
    align-items: center;
}

.JudgedPagesTable {
    max-width: fit-content;
    margin-left: auto;
    margin-right: auto;
}

.JudgedPagesTable th, td {
    border: 1px solid;
    padding: 1rem;
}

.ScoreCards {
    display: flex;
    flex-direction: row;
}

.AllScore * {
    padding-top: 0px;
    padding-bottom: 0px;
    font-size: 2rem;
}

.ScoreCard {
    padding: 1rem;
    border-width:2px;
    border-style:solid;
    border-color: var(--acc);
}