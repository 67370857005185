.Footer {
    top: 0;
    position: sticky;
    z-index: 2;
}

.FooterCategories {
    display: flex;
    justify-content: center;
    align-items: center;
}

.FooterCategories > * {
    margin: 1rem;
}

.Footer * {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    font-size: 2rem;
}

.Socials {
    flex: 1 1 auto;
}
.SocialLabel {
    display: flex;
    align-items: center;
    flex-direction: row;
    vertical-align: middle;
    text-align: center;
}
.SocialLabel > * {
    margin: 0.5rem;
}

.SponsorImg {
    height: 100%;
    overflow: hidden;
}

.Sponsors {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}

.Sponsors > * {
    flex: 1;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.LogoNLAB {
    height: 80% !important;
    margin: 0.5rem;
}

.LogoLMSA {
    height: 50% !important;
}

@media screen and (max-width: 800px) {
    .FooterCategories {
        flex-direction: column;
    }
    .SponsorImg {
        width: 100%;
        margin: 0.1rem;
        margin-bottom: 2rem;
        order: 1;
    }
}
