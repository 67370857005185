.GradePage {
    display: flex;
    margin: 1rem;
}

.ExamFile {
    display: flex;
    flex-direction: column;
    text-align: left;
}
.ExamFile > a {
    display: inline;
}

.TaskDetails {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 80vh;
}

.PointInput {
    width: 3rem;
}

.ExamFileImage {
    width: 50vw;
}
.ExamFileDoc {
    width: 50vw;
    height: 80vh
}

.TaskEntry {
    margin: 0.5rem
}