.EventTitle {
    font-size: 3rem;
}

.RsvpPage {

}

.RsvpOptions {
    display: flex;
    justify-content: center;
}

.RsvpOption {
    padding: 1rem;
}

.AffirmativeOptionCard {
    
}

.SelectButton {
    background-color: var(--accent);
    border-radius: 50%;
    font-size: 3rem;
    border-radius:400px;
    border: none;
    padding-left: 2rem;
    padding-right: 2rem;
    cursor:pointer;
    transition: all 0.5s;
}
.SelectButton:hover{
    transform: scale(1.1); 
}
.SelectButton:hover:after {
  opacity: 1;
  padding-left: 1rem;
}
.SelectButton:disabled {
    background-color: var(--secondary);
}
.SelectButton:disabled:hover {
    transform: none;
}
@media screen and (max-width: 850px) {
    .RsvpOptions {
        display: block;
        overflow: auto;
        white-space: nowrap;
    }
    .RsvpOption {
        display: inline-block;
    }
    
}

/*
@media screen and (max-width: 850px) {
    .RsvpOptions {
        flex-direction: column;
        display: block;
        overflow: auto;
        white-space: nowrap;
    }
    .AffirmativeOptionCard {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content:space-between;
    }
    .OptionName {  
        display: inline-block;
    }
    .OptionDescription {  
        display: inline-block;
    }
}*/