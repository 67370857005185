.Landing {
    box-sizing: border-box;
    overflow-x: clip;
    overflow-y: clip;
}

.Landing h1 {
    font-size: 5rem;
    text-align: center;
    margin: 3rem;
}

.IntroCont {
    height : 100vh ;
    background-image: url(../public/horizontal_grid_text.svg);
    background-position: center;
    background-size:100% 100%;
    -o-background-size: 100% 100%;
    -webkit-background-size: 100% 100%;
    background-size:cover;
}

.IntroCont * {
    background: none;
    text-shadow: -2px -2px 0 var(--background), 2px -2px 0 var(--background), -2px 2px 0 var(--background), 2px 2px 0 var(--background);
}

/*
.IntroCont * {
    background-color: var(--text);
}
*/

.Intro {
    position: relative;
    top: 30vh; 
    text-align: center;
    margin-left: 1rem;
    margin-right: 1rem;
}

.Intro h1 {
    margin: 0;
    display: inline;
    /*color: var(--background);*/
}

.Timeline {
    position: relative;
    padding: 0;
    align-items: left;
    text-align: left;
}
.Timeline::after { /*Lines*/
    content: '';
    position: absolute;
    width: 1rem;
    background-color: var(--text-shadow);
    top: 0;
    bottom: 0;
    left: 85%;
    margin-left: -1.5rem;
}


.Timeline h2 {
    font-size: 5rem;
    margin: 0;
}
.Timeline h3 {
    font-size: 3rem;
    margin: 0;
}
.Timeline p {
    font-size: 2rem;
    margin: 0;
}

.TimelineEntry {
    padding: 5rem 2rem;
    position: relative;
    background-color: inherit;
    width: 60%;
}
.TimelineEntry::after { /*Points*/
    content: '';
    position: absolute;
    width: 10rem;
    height: 10rem;
    background-color: var(--text);
    border: 1rem solid var(--background);
    top: 10rem;
    bottom: 0;
    right: -39%;
    border-radius: 50%;
    z-index: 1;
}