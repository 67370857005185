.Header {
    top: 0;
    position: sticky;
    z-index: 2;
}

.HeaderList {
    padding: 0.5rem 1rem;
    padding-bottom: 0;
    color: var(--text);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

.DesktopNav ul {
    grid-column-gap: 1.5rem;
    grid-row-gap: 1.5rem;
    display: flex;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 0;
    padding-left: 0;
}
.DesktopNav li {
    padding: 10px 20px;
    margin-bottom: 0;
    padding-left: 0;
    display: flex;
    box-sizing: border-box;
}

.HeaderList * {
    font-size: 2rem;
    text-decoration: none;
}


.NavEntry:hover{
    background-color:var(--text);
    color: var(--background);
}
.NavEntry:active{
    background-color: var(--primary);
    color:var(--text);
}

.BurgerMenu {
    color: var(--text);
}

.MobileMenu {
    transition: -webkit-transform .5s ease;
    transition: transform .5s ease;
    width: 100%
}
.MobileMenu.isOpen {
    -webkit-transform: translateX(-110%);
    -ms-transform: translateX(-110%);
    transform: translateX(-110%);
}

.MobileNav ul {
    position: fixed;
    top: 0;
    right: 0;
    width:50%;
    height: 100%;
    margin: 0;
    background: var(--background);
    text-align: right;
    list-style-position: inside;
    list-style-type: none;
}
.MobileNav li {
    padding: 10px 30px;
    padding-left: 0px;
}
.MobileNav li * {
    font-size: 2.5rem;
}

.overlay {
    position: fixed;
    top: 0;
    right: 0;
    width:100%;
    height: 100%;
  background: rgba(0, 0, 0, 0.3);
}

.DivLine {
    color: var(--text);
    width: 100%;
    border-right : 0;
    border-left: 0;
  }

.MobileNav {
    display: inline-block;
}
.DesktopNav {
    display: inline-block;
}

@media screen and (min-width: 950px) {
    .MobileNav {
        display: none;
    }
}
@media screen and (max-width: 950px) {
    .DesktopNav {
        display: none;
    }
}