.PrepContainer h2 {
    font-size: 3rem;
}

.PrepContainer * {
    text-align: left;
    margin: 1rem;
}

.Formulae {
    font-size: 2rem;
}

.FormulaeTables {
    display: flex;
    justify-content: center;
    padding: 5px;
}
.FormulaeTables img {
    flex: 1 1 0px;
    width: 0;
}

.PrepContainer p {
   font-size: 2rem;
}

.PrepMaterial {
   text-align: center;
   font-size: 3rem;
   margin: 3rem;
}

.PrepMaterial embed {
    width: 100%;
    height: 80vh;
}

.DocList * {
    font-size: 2rem;
}

@media screen and (max-width: 850px) {
    .FormulaeTables {
        display: block;
        overflow: auto;
        white-space: nowrap;
    }
    .FormulaeTables img {
        width: 80%;
        flex: 1;
    }
    .PDFViewer {
        display: none;
    }
}